import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addProductToCart = (product) => {
    const productIndex = cart.findIndex((item) => item.id === product.id);
    if (productIndex > -1) {
      const newCart = [...cart];
      newCart[productIndex].quantity += 1;
      setCart(newCart);
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const removeItemFromCart = (id) => {
    const productIndex = cart.findIndex((item) => item.id === id);
    if (productIndex > -1) {
      const newCart = [...cart];
      newCart.splice(productIndex, 1);
      setCart(newCart);
    }
  };

  const incrementQuantity = (id) => {
    const productIndex = cart.findIndex((item) => item.id === id);
    if (productIndex > -1) {
      const newCart = [...cart];
      newCart[productIndex].quantity += 1;
      setCart(newCart);
    }
  };

  const decrementQuantity = (id) => {
    const productIndex = cart.findIndex((item) => item.id === id);
    if (productIndex > -1) {
      const newCart = [...cart];
      if (newCart[productIndex].quantity > 1) {
        newCart[productIndex].quantity -= 1;
        setCart(newCart);
      }
    }
  };

  const updateSize = (id, size) => {
    const productIndex = cart.findIndex((item) => item.id === id);
    if (productIndex > -1) {
      const newCart = [...cart];
      newCart[productIndex].size = size;
      setCart(newCart);
    }
  };

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        addProductToCart,
        removeItemFromCart,
        incrementQuantity,
        decrementQuantity,
        updateSize,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
