import React from 'react';

import { NotificationProvider } from './src/context/AddItemNotificationProvider';

import RootElement from './src/components/Context/RootElement';

export const wrapRootElement = ({ element }) => (
  <RootElement>
    <NotificationProvider>{element}</NotificationProvider>
  </RootElement>
);
